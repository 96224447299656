export const duties = [
  {
    week: 1,
    courtDuty: ["Jason", "Tim", "Jacob"],
    cashDuty: ["Simon", "Max", "Dima"],
  },
  {
    week: 2,
    courtDuty: ["Niclas, Bruno"],
    cashDuty: ["Oleksandr", "Volodymyr", "Jonathan"],
  },
  {
    week: 3,
    courtDuty: ["Benedikt", "MJ"],
    cashDuty: ["Jason", "Tim", "Jacob"],
  },
  {
    week: 4,
    courtDuty: ["Ali", "Niklas"],
    cashDuty: ["Niclas", "Bruno"],
  },
  {
    week: 5,
    courtDuty: ["Daniel", "Stephan"],
    cashDuty: ["Benedikt", "MJ"],
  },
  { week: 6, courtDuty: ["Kieran", "Marcel"], cashDuty: ["Ali", "Niklas"] },
  {
    week: 7,
    courtDuty: ["Simon", "Max", "Dima"],
    cashDuty: ["Daniel", "Stephan"],
  },
  {
    week: 8,
    courtDuty: ["Oleksandr", "Volodymyr", "Jonathan"],
    cashDuty: ["Kieran", "Marcel"],
  },
];

export const games = [
  {
    date: "2024-09-21",
    time: "19:00",
    gameNr: 1,
    team1: "Tv Saarwellingen",
    team2: "DJK Saarbrücken-Rastpfuhl",
    host: 1,
    results: {
      sets: {
        1: [25, 15],
        2: [25, 13],
        3: [25, 14],
      },
      additional_data: [
        {
          type: "YouTube",
          title: "Replay",
          url: "https://youtu.be/IaTdoH1HPVk",
        },
        {
          type: "Instagram",
          title: "Highlight Saarwellingen",
          url: "https://www.instagram.com/reel/DAV1mC9sB45/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==",
        },
      ],
    },
  },
  {
    date: "2024-09-28",
    time: "14:30",
    gameNr: 1,
    team1: "DJK Saarbrücken-Rastpfuhl",
    team2: "SVK Blieskastel-Zweibrücken",
    host: 1,
    results: {
      sets: {
        1: [25, 23],
        2: [21, 25],
        3: [23, 25],
        4: [24, 26],
      },
      additional_data: [],
    },
  },
  {
    date: "2024-09-28",
    time: "14:30",
    gameNr: 2,
    team1: "DJK Saarbrücken-Rastpfuhl",
    team2: "TV Klarenthal",
    host: 1,
    results: {
      sets: {
        1: [25, 22],
        2: [26, 24],
        3: [25, 23],
      },
      additional_data: [],
    },
  },
  {
    date: "2024-11-02",
    time: "19:00",
    gameNr: 1,
    team1: "TV Düppenweiler",
    team2: "DJK Saarbrücken-Rastpfuhl",
    host: 1,
    results: {
      sets: {
        1: [25, 23],
        2: [23, 25],
        3: [25, 10],
        4: [25, 14],
      },
      additional_data: [],
    },
  },
  {
    date: "2024-11-09",
    time: "18:30",
    gameNr: 1,
    team1: "DJK Saarbrücken-Rastpfuhl",
    team2: "TV Brebach",
    host: 1,
    results: {
      sets: {
        1: [22, 25],
        2: [22, 25],
        3: [16, 25],
      },
      additional_data: [
        {
          type: "YouTube",
          title: "Replay",
          url: "https://youtu.be/7vRUhVHEAcQ",
        },
      ],
    },
  },
  {
    date: "2024-11-09",
    time: "18:30",
    gameNr: 2,
    team1: "DJK Saarbrücken-Rastpfuhl",
    team2: "TV 06 Limbach",
    host: 1,
    results: {
      sets: {
        1: [20, 25],
        2: [22, 25],
        3: [23, 25],
      },
      additional_data: [
        {
          type: "YouTube",
          title: "Replay",
          url: "https://youtu.be/L4yv4clg-XQ",
        },
      ],
    },
  },
  {
    date: "2024-11-30",
    time: "19:00",
    gameNr: 2,
    team1: "TV Saarwellingen 2",
    team2: "DJK Saarbrücken-Rastpfuhl",
    host: 1,
    results: {
      sets: {
        1: [25, 23],
        2: [25, 15],
        3: [25, 12],
      },
      additional_data: [
        {
          type: "YouTube",
          title: "Replay",
          url: "https://youtu.be/1nJ-LJ-uRcI",
        },
      ],
    },
  },
  {
    date: "2024-12-14",
    time: "15:00",
    gameNr: 2,
    team1: "TV Wiesbach 2",
    team2: "DJK Saarbrücken-Rastpfuhl",
    host: 1,
    results: {
      sets: {
        1: [25, 22],
        2: [25, 18],
        3: [25, 21],
      },
      additional_data: [
        {
          type: "YouTube",
          title: "Replay",
          url: "https://www.youtube.com/watch?v=z2Jz95Q8l_w",
        },
      ],
    },
  },
  {
    date: "2025-01-11",
    time: "19:00",
    gameNr: 1,
    team1: "TV Klarenthal",
    team2: "DJK Saarbrücken-Rastpfuhl",
    host: 1,
  },
  {
    date: "2025-01-18",
    time: "16:00",
    gameNr: 1,
    team1: "TV Brebach",
    team2: "DJK Saarbrücken-Rastpfuhl",
    host: 1,
  },
  {
    date: "2025-02-01",
    time: "14:30",
    gameNr: 1,
    team1: "DJK Saarbrücken-Rastpfuhl",
    team2: "TV Düppenweiler",
    host: 1,
  },
  {
    date: "2025-02-01",
    time: "14:30",
    gameNr: 2,
    team1: "DJK Saarbrücken-Rastpfuhl",
    team2: "TV Wiesbach 2",
    host: 1,
  },
  {
    date: "2025-02-15",
    time: "15:00",
    gameNr: 1,
    team1: "TV 06 Limbach",
    team2: "DJK Saarbrücken-Rastpfuhl",
    host: 1,
  },
  {
    date: "2025-03-08",
    time: "15:00",
    gameNr: 1,
    team1: "SVK Blieskastel-Zweibrücken",
    team2: "DJK Saarbrücken-Rastpfuhl",
    host: 1,
  },
  {
    date: "2025-03-15",
    time: "18:30",
    gameNr: 1,
    team1: "DJK Saarbrücken-Rastpfuhl",
    team2: "TV Saarwellingen",
    host: 1,
  },
  {
    date: "2025-03-15",
    time: "18:30",
    gameNr: 2,
    team1: "DJK Saarbrücken-Rastpfuhl",
    team2: "TV Saarwellingen 2",
    host: 1,
  },
];
